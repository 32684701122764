import React, { useState } from "react";
import { FluidObject } from "gatsby-image";
import styled from "styled-components";
import { navigate } from "gatsby";

import { Container, H3 } from "@util/standard";
import { Image } from "@sub";
import { SanityWork } from "@graphql-types";
import {
  colors,
  pages,
  SMALL_LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
  TWENTY_INCH_SCREEN
} from "@util/constants";

interface Props {
  work: SanityWork | null | undefined;
  smaller?: boolean;
}

const WorkPreview = ({ work, smaller }: Props) => {
  const [hover, setHover] = useState(false);
  if (!work) return null;

  const Image1 = () =>
    work.images == null ? null : (
      <ImageWrapper>
        <Image
          fluid={work.images[0]?.asset?.fluid as FluidObject}
          objectFit="cover"
          height="100%"
          data={work.images?.[0]!}
        />
      </ImageWrapper>
    );

  const RenderedImage = () => {
    if (work.images == null) {
      return null;
    }
    if (smaller) {
      return (
        <ImagesWrapper>
          <Image1 />
        </ImagesWrapper>
      );
    }

    return (
      <ImageContainer>
        <Image
          fluid={work.images[0]?.asset?.fluid as FluidObject}
          data={work.images?.[0]!}
          isBackground
          backgroundStyle={{
            width: "100%",
            height: "100%"
          }}
        />
      </ImageContainer>
    );
  };

  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };

  const OverlayInfo = () => (
    <Overlay hover={hover}>
      {smaller ? (
        <Container justifyContent="center" alignItems="center">
          <H3>{work.title} </H3>
        </Container>
      ) : (
        <InfoContainer>
          <OverlayTitle>{work.summary}</OverlayTitle>
          <span>{work.title}</span>
        </InfoContainer>
      )}
    </Overlay>
  );

  return (
    <Wrapper
      onClick={() => navigate(`${pages.work}/${work.slug?.current ?? ""}/`)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <RenderedImage />
      <OverlayInfo />
    </Wrapper>
  );
};

export default WorkPreview;

const Wrapper = styled(Container)`
  width: 100%;
  margin: 0;
  cursor: url("/cursorClick.svg"), auto;
  position: relative;
  overflow: hidden;
`;

const Overlay = styled.div<{ hover: boolean }>`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 1;
  transition: 0.2s;
  background: linear-gradient(270deg, #8739e6 14.11%, #de6aeb 90%);
  color: ${colors.white};
  h3 {
    color: ${colors.white};
    font-size: 30px;
    text-align: center;
  }
  overflow: hidden;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`;

const OverlayTitle = styled.span`
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 15px;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    font-size: 24px;
    line-height: 28px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

const InfoContainer = styled(Container)`
  flex-direction: column;
  width: 100%;
  margin: 20px 30px;
  justify-content: flex-end;
`;

const ImageContainer = styled.div<{
  backgroundUrl?: string | null | undefined;
}>`
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;

  @media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
    height: 400px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 300px;
  }
`;

const ImagesWrapper = styled.div<{ hover?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
`;
