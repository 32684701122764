import React from "react";
import { graphql, PageProps } from "gatsby";

import WorkHero from "@components/work/workHero";
import AllWork from "@components/work";
import { AnimationContainer } from "@sub";
import { animationType } from "@util/constants";
import { Query } from "@graphql-types";
import SEO from "@components/shared/seo";
import { Container } from "@util/standard";
interface Props extends PageProps {
  data: Query;
}

export default function WorkLanding({ data }: Props) {
  const { sanityWorkLanding } = data;

  return (
    <div>
      <SEO seoData={sanityWorkLanding?.seo} slug="work" />
      <AnimationContainer animationType={animationType.slideInBottom}>
        <Container
          tabletWidth="100%"
          maxWidth="90%"
          margin="250px auto 140px auto"
          mobileMargin="100px auto 0 auto "
          width="90%"
        >
          <WorkHero />
        </Container>
      </AnimationContainer>
      <AnimationContainer animationType={animationType.slideInBottom}>
        <AllWork />
      </AnimationContainer>
    </div>
  );
}

export const query = graphql`
  query WorkQuery {
    sanityWorkLanding {
      seo {
        pageDescription
        pageKeyWords
        pageTitle
        jsonLD {
          _key
          _type
          language
          filename
          code
        }
        slug {
          current
        }
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
