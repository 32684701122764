import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Container, GiveMeSomeSpace, GridContainer } from "@util/standard";
import { Query } from "@graphql-types";
import WorkPreview from "@components/shared/workPreview";
import { Button } from "@sub";
import { MOBILE_BREAKPOINT, IPAD_BREAKPOINT } from "@util/constants";

const Wrapper = styled(Container)`
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const StyledGridContainer = styled(GridContainer)`
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const NUMBER_TO_LOAD = 6;

const AllWork = () => {
  const [noOfItemsToLoad, setNoOfItemsToLoad] = useState(NUMBER_TO_LOAD);

  const { allSanityWork }: Query = useStaticQuery(graphql`
    {
      allSanityWork(sort: { fields: order, order: ASC }) {
        nodes {
          _id
          title
          slug {
            current
          }
          summary
          images {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);

  if (allSanityWork == null) {
    return null;
  }

  const itemsToLoad = allSanityWork.nodes.slice(0, noOfItemsToLoad);

  const handleLoadMoreClick = () => {
    setNoOfItemsToLoad(noOfItemsToLoad + NUMBER_TO_LOAD);
  };

  return (
    <Wrapper maxWidth="90%" width="90%">
      <StyledGridContainer repeat={2}>
        {itemsToLoad.map((work) => {
          if (work == null) {
            return null;
          }

          return <WorkPreview key={work._id} work={work} />;
        })}
      </StyledGridContainer>
      <GiveMeSomeSpace space={20} />
      {noOfItemsToLoad <= allSanityWork.nodes.length && (
        <Button theme="base" text="Load more" onClick={handleLoadMoreClick} />
      )}
    </Wrapper>
  );
};

export default AllWork;
