import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Container } from "@util/standard";
import { Query } from "@graphql-types";
import { BlocksContent } from "@sub";
import { MOBILE_BREAKPOINT } from "@util/constants";

const Wrapper = styled(Container)`
  margin: 0;
  margin-bottom: 50px;
  width: 70%;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 50px;
    margin: 0;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin: 30px auto;

    .heading-1 {
      font-size: 50px !important;
    }
  }
`;

const WorkHero = () => {
  const { sanityWorkLanding }: Query = useStaticQuery(graphql`
    {
      sanityWorkLanding {
        heroTitle {
          _rawColumnContent
        }
      }
    }
  `);

  if (sanityWorkLanding == null) return null;

  return (
    <Wrapper>
      <BlocksContent blocks={sanityWorkLanding.heroTitle?._rawColumnContent} />
    </Wrapper>
  );
};

export default WorkHero;
